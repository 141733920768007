import { useState } from "react";
import { Control, useWatch } from "react-hook-form";

import { Button } from "components/common/buttons/Button";
import { Loader } from "components/common/loaders/Loader";
import { StepTitle } from "./components/StepTitle";
import { FormValues } from "./RequestChemicalForm";
import { ErrorText } from "components/common/typography/ErrorText";
import { PRIVACY_POLICY } from "constants/routeConstants";

type FormStepThreeProps = {
  isSubmitting: boolean;
  control: Control<FormValues>;
};

export const FormStepThree = ({
  isSubmitting,
  control,
}: FormStepThreeProps) => {
  const [gdprConsent, setGdprConsent] = useState(false);
  const chemicalSmiles = useWatch({
    control,
    name: "chemicalSmiles",
  });

  return (
    <div className="flex flex-col my-6">
      <StepTitle
        title="Step Three"
        description="Submit design. We will update our top designs frequently and list the first winners in early 2023."
      />
      <div className="flex flex-col w-full items-center justify-center">
        {isSubmitting ? (
          <Loader loadingText="Submitting your request..." />
        ) : (
          <>
            <div className="flex flex-col items-center justify-center">
              <div className="flex justify-center items-center">
                <input
                  type="checkbox"
                  id="gdprConsent"
                  name="gdprConsent"
                  checked={gdprConsent}
                  onChange={(e) => setGdprConsent(e.target.checked)}
                  className="mr-2 h-5 w-5 rounded-full shadow focus:ring-0 text-purple-500 cursor-pointer"
                />
                <label htmlFor="gdprConsent" className="block text-xs">
                  I consent to my submitted data being collected and stored. For
                  more information see the{" "}
                  <a
                    href={PRIVACY_POLICY}
                    target="_blank"
                    className="underline font-bold"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                  .
                </label>
              </div>
              <Button
                id="submitRequestBtn"
                type="submit"
                color="primary"
                disabled={isSubmitting || !chemicalSmiles || !gdprConsent}
                className="text-3xl py-5 px-12 max-w-[310px] mt-4 font-thin disabled:hover:cursor-not-allowed"
              >
                Submit Design
              </Button>
            </div>
            {!chemicalSmiles && (
              <ErrorText
                errorMessage="*Please input and confirm a Chemical in order to submit your
                bid"
              />
            )}
            {!gdprConsent && (
              <ErrorText errorMessage="*Please check the box to consent to your data being collected and stored." />
            )}
          </>
        )}
      </div>
    </div>
  );
};
