import React, { ReactNode } from "react";
import { Dialog } from "@headlessui/react";

type ModalContainerProps = {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  title: string;
  children: ReactNode;
};

export const ModalContainer = ({
  isOpen,
  setIsOpen,
  title,
  children,
}: ModalContainerProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="mx-auto w-full md:w-[500px] rounded-lg bg-white p-4 drop-shadow-lg text-gray-500">
          <Dialog.Title className="font-bold text-2xl">{title}</Dialog.Title>
          {children}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};
