import { ChemifyLogo } from "../common/logos/ChemifyLogo";
import { useSearchParams } from "react-router-dom";
import { LinkButton } from "components/common/buttons/LinkButton";
import { HOME } from "constants/routeConstants";
import { ChemicalQuantity } from "types/types";
import { getCorrectQuantity } from "utils/getCorrectQuantity";

export const SuccessBanner = () => {
  const [searchParams] = useSearchParams();

  const quantity = searchParams.get("quantity");

  return (
    <section className="bg-white text-gray-500 w-full">
      <div className="flex flex-col px-2 py-8 md:min-h-[450px] md:py-24 w-full">
        <div className="flex flex-col items-center">
          <ChemifyLogo size="medium" />
          <h1 className="text-green-500 font-light mt-6">Success!</h1>
          <p className="my-8 text-xl text-center">
            Thank you {searchParams.get("firstName")}, your bid for{" "}
            {getCorrectQuantity(quantity as ChemicalQuantity)} of chemical{" "}
            <br />{" "}
            <span className="text-2xl mb-0 break-all">
              {searchParams.get("chemical")}
            </span>
            <br /> for ${searchParams.get("bidPrice")} has been submitted
            successfully.
          </p>
          <LinkButton url={HOME} color="primary">
            Bid Again?
          </LinkButton>
        </div>
      </div>
    </section>
  );
};
