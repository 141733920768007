import { LinkButton } from "../common/buttons/LinkButton";
import { ChemifyLogo } from "../common/logos/ChemifyLogo";

export const HeaderBanner = () => {
  return (
    <div className="container mx-auto max-w-screen-lg">
      <section className="bg-white w-full px-4 py-4">
        <div className="grid grid-cols-1 md:grid-cols-2 md:min-h-[450px] md:py-24 w-full">
          <div className="flex flex-col items-start text-gray-500 md:basis-6/12 md:pr-8">
            <h1 className="hidden">Chemify ChemStarter</h1>
            <ChemifyLogo size="medium" />
            <p className="mt-4 text-xl text-justify md:text-left">
              Chemify has developed a full stack approach to digital chemistry
              opening access to chemical space. The aim of ChemStarter is to
              allow the community to explore chemical space by designing complex
              or hard to find molecules they need access to for discovery.
            </p>
          </div>
          <div className="flex justify-center items-center w-full my-8 ">
            <LinkButton openInNewTab color="primary" url="https://chemify.io">
              chemify.io{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                />
              </svg>
            </LinkButton>
          </div>
        </div>
      </section>
    </div>
  );
};
