import { ChemicalQuantity } from "types/types";

export const getCorrectQuantity = (quantity: ChemicalQuantity) => {
  switch (quantity) {
    case ChemicalQuantity["1Gram"]:
      return "1 g";
    case ChemicalQuantity["10Grams"]:
      return "10 g";
    case ChemicalQuantity["250Milligrams"]:
      return "250 mg";
    case ChemicalQuantity["5Milligrams"]:
      return "5 mg";
    default:
      return quantity;
  }
};
