import { useEffect } from "react";

import App from "App";
import { HeaderBanner } from "components/headerBanner/HeaderBanner";
import { LegalDisclaimer } from "components/LegalDisclaimer";
import { RequestChemicalContainer } from "components/requestChemical/RequestChemicalContainer";
import { Layout } from "layout/Layout";
import { GApageView } from "utils/googleAnalyticsTracking";

export const Home = () => {
  useEffect(() => {
    GApageView("homepage");
  }, []);

  return (
    <App>
      <Layout title="Chemify - ChemStarter">
        <HeaderBanner />
        <RequestChemicalContainer />
        <LegalDisclaimer />
      </Layout>
    </App>
  );
};
