import React from "react";
import { UseFormRegister } from "react-hook-form";

type SelectFieldProps = {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  register: UseFormRegister<any>;
};

export const SelectField = ({
  register,
  options,
  name,
  label,
}: SelectFieldProps) => {
  return (
    <>
      <label
        className="block capitalize tracking-wide text-white text-md font-bold mb-2"
        htmlFor="purity"
      >
        {label}
      </label>
      <div className="inline-block relative w-full mb-6 md:mb-0">
        <select
          id={name}
          {...register(name)}
          className="block appearance-none w-full bg-gray-200 border border-gray-300 hover:border-gray-500 px-4 py-3 pr-8 text-gray-500 rounded leading-tight font-thin focus:outline-none focus:shadow-outline focus:bg-white hover:cursor-pointer"
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
    </>
  );
};
