const fetchApiHelper = async (url: string) => {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error("Something went wrong.");
  }
  const data = await response.json();

  return data;
};

export const fetchChemicalDetailsWithSmiles = async (smiles: string) => {
  const data = await fetchApiHelper(
    `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/smiles/${encodeURIComponent(
      smiles
    )}/property/IUPACName,CanonicalSMILES/JSON`
  );

  return data.PropertyTable.Properties[0];
};

export const fetchChemicalDetailsWithName = async (name: string) => {
  const data = await fetchApiHelper(
    `https://pubchem.ncbi.nlm.nih.gov/rest/pug/compound/name/${encodeURIComponent(
      name
    )}/property/IUPACName,CanonicalSMILES/JSON`
  );

  return data.PropertyTable.Properties[0];
};
