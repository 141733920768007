import React from "react";

type ErrorMessageProps = {
  children: React.ReactNode | string;
};

export const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return (
    <div className="flex justify-center w-full my-4 mx-auto max-w-md p-2 bg-white rounded-lg">
      <p className="text-red-500 m-0">{children}</p>
    </div>
  );
};
