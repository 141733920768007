import { Button } from "components/common/buttons/Button";
import { ErrorMessage } from "components/ErrorMessage";

type ChemicalErrorProps = {
  error: string;
  onClickCancel: () => void;
};

export const ChemicalError = ({ error, onClickCancel }: ChemicalErrorProps) => {
  return (
    <>
      <ErrorMessage>
        {error} Please check your input and try again.
      </ErrorMessage>
      <div className="flex justify-end mt-8">
        <Button
          id="cancelBtn"
          onClick={onClickCancel}
          className="w-[120px]"
          color={"secondary"}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
