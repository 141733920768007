import { Button } from "../buttons/Button";

type InputFieldWithSearchProps = {
  label: string;
  name: string;
  placeholder: string;
  helperText: string;
  value: string;
  onChange: (arg0: any) => void;
  onClick: () => void;
  disabled?: boolean;
};

export const InputFieldWithSearch = ({
  label,
  name,
  placeholder,
  value,
  onChange,
  onClick,
  helperText,
  disabled,
}: InputFieldWithSearchProps) => {
  return (
    <>
      <label
        className="block capitalize tracking-wide text-white text-md font-bold mb-2"
        htmlFor={name}
      >
        {label}
      </label>
      <div className="flex w-full">
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          className={`appearance-none block w-full bg-gray-200 text-gray-500 border border-gray-300 rounded py-3 px-4 mr-2 mb-1 leading-tight  focus:outline-none focus:bg-white`}
          onChange={onChange}
        />
        <Button
          id={name}
          color="primary"
          onClick={onClick}
          className="w-[150px] mb-[2px]"
          disabled={disabled}
        >
          Search
        </Button>
      </div>

      <p className="text-white text-xs">{helperText}</p>
    </>
  );
};
