import { useEffect } from "react";
import { Footer } from "components/Footer";
import { Navigation } from "components/navigation/Navigation";

type LayoutProps = {
  title: string;
  children: React.ReactNode;
};

export const Layout = ({ title, children }: LayoutProps) => {
  useEffect(() => {
    document.title = title;
  });

  return (
    <>
      <main className="bg-white">
        <Navigation />
        <div className="min-h-screen">{children}</div>
        <Footer />
      </main>
    </>
  );
};
