import ReactGA from "react-ga4";

export const GApageView = (page: string) => {
  ReactGA.send({ hitType: "pageview", page });
};

export const GAevent = (
  categoryName: string,
  eventName: string,
  nonInteraction: boolean
) => {
  ReactGA.event({
    category: categoryName,
    action: eventName,
    nonInteraction,
  });
};
