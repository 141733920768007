import { CONFIRMED_CHEMICAL_SENTENCE } from "../FormStepOne";

type ConfirmedChemicalProps = {
  confirmedChemical: string;
};

export const ConfirmedChemical = ({
  confirmedChemical,
}: ConfirmedChemicalProps) => {
  return (
    <div className="flex mx-auto border-white border-2 rounded-md p-6 my-4 justify-center w-full md:w-[500px]">
      <span className="text-3xl text-center">
        Chemical Selected: <br />{" "}
        <p
          className={`break-all ${
            confirmedChemical === CONFIRMED_CHEMICAL_SENTENCE
              ? "text-red-300 italic"
              : ""
          }`}
        >
          {confirmedChemical}
        </p>
      </span>
    </div>
  );
};
