import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";

import { PrivacyPolicyPage } from "pages/legal/PrivacyPolicyPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ErrorPage } from "./components/ErrorPage";
import { SUCCESS_PAGE, HOME, PRIVACY_POLICY } from "./constants/routeConstants";

import { Home } from "./pages/home/Home";
import { SuccessPage } from "./pages/success/SuccessPage";

process.env.NODE_ENV === "production" && ReactGA.initialize("G-ZH3DL89E2V");

const router = createBrowserRouter([
  {
    path: HOME,
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: SUCCESS_PAGE,
    element: <SuccessPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: PRIVACY_POLICY,
    element: <PrivacyPolicyPage />,
    errorElement: <ErrorPage />,
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
