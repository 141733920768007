import { InputField } from "../../common/fields/InputField";
import { FieldErrorsImpl, UseFormRegister } from "react-hook-form";
import { FormValues } from "./RequestChemicalForm";
import { StepTitle } from "./components/StepTitle";
import { SelectField } from "../../common/fields/SelectField";
import { ChemicalQuantity } from "types/types";

type FormStepTwoProps = {
  register: UseFormRegister<FormValues>;
  errors: Partial<FieldErrorsImpl<FormValues>>;
};

const purityOptions = [
  { value: "95", label: "Reagent Grade - 95 %" },
  { value: "99", label: "Analytical Grade - 99 %" },
  { value: "99.5", label: "Ultra Pure - 99.5 %" },
];

// 5mg, 250mg, 1g
const quantityOptions = [
  { value: ChemicalQuantity["5Milligrams"], label: "5 mg" },
  { value: ChemicalQuantity["250Milligrams"], label: "250 mg" },
  { value: ChemicalQuantity["1Gram"], label: "1 g" },
  { value: ChemicalQuantity["10Grams"], label: "10 g" },
];

export const FormStepTwo = ({ register, errors }: FormStepTwoProps) => {
  return (
    <div className="flex flex-col mb-6 mt-12">
      <StepTitle
        title="Step Two"
        description="Second, please enter the required information below including a bid of how much you would be prepared to pay for the chemical and quantity requested."
      />
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <SelectField
            label="Quantity*"
            name="quantity"
            options={quantityOptions}
            register={register}
          />
        </div>
        <div className="w-full md:w-1/2 px-3">
          <SelectField
            label="Purity*"
            name="purity"
            options={purityOptions}
            register={register}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3 mb-6 md:mb-0">
          <InputField
            name="bidPrice"
            label="Your Bid*"
            placeholder="Place your bid in $ USD"
            formType="number"
            register={register}
            required={true}
            error={errors.bidPrice}
            helperText="Please enter a bid for the quantity you have selected"
            icon={
              <span className="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-lg items-center justify-center w-8 pl-3 pt-[10px]">
                $
              </span>
            }
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <InputField
            name="firstName"
            label="First Name*"
            placeholder="First Name"
            formType="text"
            register={register}
            required={true}
            error={errors.firstName}
          />
        </div>
        <div className="w-full md:w-1/2 px-3">
          <InputField
            name="lastName"
            label="Last Name*"
            placeholder="Last Name"
            formType="text"
            required={true}
            register={register}
            error={errors.lastName}
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <InputField
            name="email"
            label="Email*"
            placeholder="example@email.com"
            formType="text"
            required={true}
            register={register}
            error={errors.email}
          />
        </div>
        <div className="w-full md:w-1/2 px-3">
          <InputField
            name="affiliation"
            label="Affiliation*"
            placeholder="e.g. University of Glasgow"
            formType="text"
            required={true}
            register={register}
            error={errors.affiliation}
            helperText="Please provide details about the company or institute you are working on behalf of"
          />
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3 mb-6 md:mb-0">
          <InputField
            name="additionalInfo"
            label="Additional Information"
            placeholder="Please type here..."
            formType="text"
            register={register}
            error={errors.additionalInfo}
            helperText="Please provide any further information that could prove useful - timescale, usage etc"
          />
        </div>
      </div>
    </div>
  );
};
