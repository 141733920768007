import { HOME } from "../../../constants/routeConstants";

type ChemifyLogoProps = {
  size: "small" | "medium" | "large";
};

export const ChemifyLogo = ({ size }: ChemifyLogoProps) => {
  return (
    <a href={HOME}>
      <div className="flex items-center">
        <img
          src="https://res.cloudinary.com/chemify/image/upload/v1652971088/chemify-web/chemify_logo_wmohln.svg"
          alt="Chemify Logo"
          width={size === "small" ? 100 : 150}
          height={size === "small" ? 42 : 63}
        />
      </div>
    </a>
  );
};
