type StepTitleProps = {
  title: string;
  description: string;
};

export const StepTitle = ({ title, description }: StepTitleProps) => {
  return (
    <div className="flex flex-col items-center mb-2 w-full">
      <h2>{title}</h2>
      <p className="text-center">{description}</p>
    </div>
  );
};
