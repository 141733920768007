import { Button } from "components/common/buttons/Button";
import { NO_CHEMICAL_NAME, NO_CHEMICAL_SMILES } from "constants/constants";

type ChemicalConfirmationProps = {
  name: string;
  smiles: string;
  onClickCancel: () => void;
  onClickConfirm: () => void;
};

export const ChemicalConfirmation = ({
  name,
  smiles,
  onClickCancel,
  onClickConfirm,
}: ChemicalConfirmationProps) => {
  return (
    <div className="w-full break-words">
      <p className="mb-2">
        Please see the information for the chemical requested:
      </p>
      <p className="mb-2 font-semibold text-xl">Chemical Name: {name}</p>
      <p className="mb-2 font-semibold text-xl">SMILES: {smiles}</p>
      <p>If this information is correct please press the confirm button.</p>
      <div className="flex w-full justify-end mt-8">
        <Button
          id="cancelBtn"
          onClick={onClickCancel}
          className="w-[120px]"
          color={"secondary"}
        >
          Cancel
        </Button>
        <Button
          id="confirmChemicalBtn"
          className="ml-2 w-[120px]"
          onClick={onClickConfirm}
          color={"primary"}
          disabled={name === NO_CHEMICAL_NAME || smiles === NO_CHEMICAL_SMILES}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
