import { Loader } from "components/common/loaders/Loader";

export const KetcherSkeleton = () => {
  return (
    <div role="status" className="space-y-2.5 animate-pulse w-full">
      <div className="flex items-center space-x-2 w-full">
        <div className="h-[400px] bg-gray-300 rounded-md dark:bg-gray-600 w-12"></div>
        <div className="h-[400px] bg-gray-300 rounded-md dark:bg-gray-600 w-full"></div>
        <div className="h-[400px] bg-gray-200 rounded-md dark:bg-gray-700 w-12"></div>
      </div>
      <Loader loadingText="Loading Chemical Canvas..." />
    </div>
  );
};
