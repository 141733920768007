import React, { Suspense } from "react";
import { UseFormSetValue } from "react-hook-form";

import { Button } from "components/common/buttons/Button";
import { InputFieldWithSearch } from "components/common/fields/InputFieldWithSearch";
import { Loader } from "components/common/loaders/Loader";
import { ModalContainer } from "components/common/modals/ModalContainer";
import {
  fetchChemicalDetailsWithName,
  fetchChemicalDetailsWithSmiles,
} from "services/pubChemApi";
import { handleUnknownError } from "utils/handleUnknownError";
import { FormValues } from "./RequestChemicalForm";
import { StepTitle } from "./components/StepTitle";
import { ChemicalConfirmation } from "./components/ChemicalConfirmation";
import { ChemicalError } from "./components/ChemicalError";
import { ConfirmedChemical } from "./components/ConfirmedChemical";
import { NO_CHEMICAL_NAME, NO_CHEMICAL_SMILES } from "constants/constants";
import { KetcherSkeleton } from "./ketcher/KetcherSkeleton";
import { GAevent } from "utils/googleAnalyticsTracking";

type FormStepOneProps = {
  setValue: UseFormSetValue<FormValues>;
};

export const CONFIRMED_CHEMICAL_SENTENCE = "No chemical confirmed";

const LazyKetcherEditor = React.lazy(() => import("./ketcher/KetcherEditor"));

export const FormStepOne = ({ setValue }: FormStepOneProps) => {
  const [smiles, setSmiles] = React.useState("");
  const [name, setName] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [confirmedChemical, setConfirmedChemical] = React.useState(
    CONFIRMED_CHEMICAL_SENTENCE
  );

  /**
   * @function handleChemicalSearchSmiles
   * @description Fetches chemical details from PubChem API using SMILES
   */
  const handleChemicalSearchSmiles = async () => {
    GAevent("chemical search", "search smiles", false);

    setIsOpen(true);
    setLoading(true);
    setError("");

    try {
      if (smiles) {
        const data = await fetchChemicalDetailsWithSmiles(smiles);
        setName(data.IUPACName || NO_CHEMICAL_NAME);
        setSmiles(data.CanonicalSMILES || NO_CHEMICAL_SMILES);
        await KetcherFunctions.setMolecule(data.CanonicalSMILES || "");
      }
    } catch (error: any) {
      const errorMessage = handleUnknownError(error);
      setError(errorMessage);
    }
    setLoading(false);
  };

  /**
   * @function handleChemicalSearchName
   * @description Fetches chemical details from PubChem API using chemical name
   */
  const handleChemicalSearchName = async () => {
    GAevent("chemical search", "search name", false);

    setIsOpen(true);
    setLoading(true);
    setError("");

    try {
      if (name) {
        const data = await fetchChemicalDetailsWithName(name);
        setName(data.IUPACName || NO_CHEMICAL_NAME);
        setSmiles(data.CanonicalSMILES || NO_CHEMICAL_SMILES);
        KetcherFunctions.setMolecule(data.CanonicalSMILES || "");
      }
    } catch (error: any) {
      const errorMessage = handleUnknownError(error);
      setError(errorMessage);
    }
    setLoading(false);
  };

  /**
   * @function handleChemicalDrawn
   * @description Sets SMILES and name of chemical drawn from Ketcher
   */
  const handleChemicalDrawn = async () => {
    GAevent("chemical search", "search drawn", false);

    setIsOpen(true);
    setLoading(true);
    setError("");

    const smilesFromKetcher = await KetcherFunctions.getSmiles();
    const sanitisedSmiles = smilesFromKetcher.replaceAll("/", "");

    try {
      if (smilesFromKetcher) {
        const data = await fetchChemicalDetailsWithSmiles(sanitisedSmiles);
        setName(data.IUPACName || NO_CHEMICAL_NAME);
        setSmiles(data.CanonicalSMILES || NO_CHEMICAL_SMILES);
      }
    } catch (error: any) {
      const errorMessage = handleUnknownError(error);
      setError(errorMessage);
    }
    setLoading(false);
  };

  const handleConfirmChemical = () => {
    setValue("chemicalSmiles", smiles);
    setValue("chemicalName", name);
    setConfirmedChemical(smiles);
    setIsOpen(false);
  };

  const handleCancelChemical = () => {
    setConfirmedChemical(CONFIRMED_CHEMICAL_SENTENCE);
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col my-6">
      <StepTitle
        title="Step One"
        description="First, please enter either the SMILES, the Chemical Name or Draw the chemical in the canvas below."
      />
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <InputFieldWithSearch
            label="SMILES"
            name="chemicalSmiles"
            placeholder="Enter SMILES here"
            value={smiles}
            onChange={(e) => setSmiles(e.target.value)}
            onClick={handleChemicalSearchSmiles}
            helperText="Enter the SMILES here rather than drawing the molecule"
            disabled={!smiles}
          />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <InputFieldWithSearch
            label="Chemical Name"
            name="chemicalName"
            placeholder="Enter the Chemical Name here"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onClick={handleChemicalSearchName}
            helperText="Enter the Chemical Name here rather than drawing the molecule"
            disabled={!name}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <Suspense fallback={<KetcherSkeleton />}>
          <LazyKetcherEditor />
          <div className="flex justify-between">
            <p className="text-white text-xs">
              Draw the chemical which you would like in the above canvas
            </p>
            <Button onClick={handleChemicalDrawn} color="primary">
              Confirm Chemical Drawn
            </Button>
          </div>
        </Suspense>
      </div>

      <ConfirmedChemical confirmedChemical={confirmedChemical} />

      {/* Modal Container */}
      <ModalContainer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Chemical Requested"
      >
        {loading ? (
          <div className="flex w-full justify-center my-8">
            <Loader loadingText="Loading..." />
          </div>
        ) : (
          <>
            {error ? (
              <ChemicalError
                error={error}
                onClickCancel={handleCancelChemical}
              />
            ) : (
              <ChemicalConfirmation
                name={name}
                smiles={smiles}
                onClickCancel={handleCancelChemical}
                onClickConfirm={handleConfirmChemical}
              />
            )}
          </>
        )}
      </ModalContainer>
    </div>
  );
};
