export const dummyBidData = [
  {
    chemicalName: "tert-butyl-3-(4-cyanophenyl)acrylate",
    chemicalSmiles: "O=C(OC(C)(C)C)/C=C/C2=CC=C([N+]([O-])=O)C=C2",
    bidPrice: 300,
    quantity: 250,
    purity: 99,
  },
  {
    chemicalName: "triazole-2-acetic acid",
    chemicalSmiles: "ClC3=NC=C(S(NCC(N=N4)=CN4CC(O)=O)(=O)=O)C=C3",
    bidPrice: 420,
    quantity: 10000,
    purity: 95,
  },
  {
    chemicalName: "Boc-Piperidine-3-hydroxy-2'-Nitrobenzenesulfonate",
    chemicalSmiles:
      "CC(C)(OC(N[C@@H](C(OC)=O)COS(C2=CC(C(O)=O)=CC=C2)(=O)=O)=O)C",
    bidPrice: 310,
    quantity: 5,
    purity: 99.5,
  },
  {
    chemicalName: "chloro-N-(4-chloropyrimidin-2-yl)propane-1-sulfonamide",
    chemicalSmiles: "CC(C)S(=O)(=O)N[C@H]1CCOC[C@@H]1c2ccc(I)cc2",
    bidPrice: 405,
    quantity: 250,
    purity: 99,
  },
  {
    chemicalName: "N-3-Trifluoromethylbenzylbenzamide",
    chemicalSmiles: "O=C(NCCOC)C2=CC=CC=C2",
    bidPrice: 380,
    quantity: 250,
    purity: 99.5,
  },
  {
    chemicalName: "4-Iodophenyl 4-vinylbenzyl ether",
    chemicalSmiles: "Ic1ccc(Oc2ccc(I)cc2)cc1",
    bidPrice: 280,
    quantity: 1000,
    purity: 95,
  },
  {
    chemicalName: "1-acetylamino-7-naphthol",
    chemicalSmiles: "O=CC2=C(C)C([N+]([O-])=O)=C(C)C=N2",
    bidPrice: 980,
    quantity: 10000,
    purity: 99,
  },
  {
    chemicalName: "5-Maleimidovaleric acid-NHS",
    chemicalSmiles: "O=C(CCCCN1C(=O)C=CC1=O)ON2C(=O)CCC2=O",
    bidPrice: 700,
    quantity: 1000,
    purity: 99.5,
  },
  {
    chemicalName: "(1-​Benzyl-​1H-​1,​2,​3-​triazol-​4-​yl)​methanol",
    chemicalSmiles: "OCC2=CN(N=N2)CC3=CC=CC=C3",
    bidPrice: 782,
    quantity: 1000,
    purity: 95,
  },
  {
    chemicalName: "7-bromo-1-hydroxynaphthalene",
    chemicalSmiles: "OC1=C2C=C(Br)C=CC2=CC=C1",
    bidPrice: 250,
    quantity: 1000,
    purity: 99.5,
  },
  {
    chemicalName: "6-Maleimidocaproic acid sulfo-NHS sodium salt",
    chemicalSmiles: "O=C(CCCCCN1C(=O)C=CC1=O)ON2C(=O)CC(S(=O)(=O)O)C2=O",
    bidPrice: 1001,
    quantity: 250,
    purity: 99,
  },
];
