import { RequestChemicalForm } from "./form/RequestChemicalForm";
import { RequestChemicalIntro } from "./RequestChemicalIntro";

export const RequestChemicalContainer = () => {
  return (
    <section
      className={`bg-gradient-to-b from-[#B83F93] to-[#9C6B9A] text-white`}
    >
      <div className="container mx-auto max-w-screen-lg py-12 px-4">
        <RequestChemicalIntro />
        <RequestChemicalForm />
      </div>
    </section>
  );
};
