import { dummyBidData } from "data/dummyBidData";
import { ChemicalQuantity } from "types/types";
import { getCorrectQuantity } from "utils/getCorrectQuantity";

export const TopBidsTable = () => {
  const sortedData = dummyBidData.sort((a, b) => b.bidPrice - a.bidPrice);

  return (
    <div className="flex flex-col text-gray-500 my-8 px-2">
      <h2 className="text-3xl font-light text-center">
        Top Bids for Chemicals
      </h2>
      <p className="text-xl text-center mb-8">
        The following table shows the current top bids for chemicals.
      </p>

      <div className="w-full overflow-x-scroll">
        <table className="table-auto w-full font-light">
          <thead>
            <tr className="text-2xl text-left bg-purple-500 text-white">
              <th className="p-3 rounded-l-md">Chemical</th>
              <th className="p-3">Quantity</th>
              <th className="p-3">Purity</th>
              <th className="p-3 rounded-r-md">Bid</th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((bid) => (
              <tr
                className="text-lg border-b-1 border-b-slate-300 last:border-b-2 last:border-b-purple-500 odd:bg-slate-100"
                key={bid.chemicalSmiles}
              >
                <td className="p-3">
                  <span className="flex flex-col">
                    <span>{bid.chemicalName}</span>
                    <span className="text-xs">{bid.chemicalSmiles}</span>
                  </span>
                </td>
                <td className="p-3">
                  {getCorrectQuantity(String(bid.quantity) as ChemicalQuantity)}
                </td>
                <td className="p-3">{String(bid.purity)} %</td>
                <td className="p-3">$ {bid.bidPrice}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
