import { ChemifyLogo } from "./common/logos/ChemifyLogo";

export const Footer = () => {
  return (
    <footer
      className="
    bg-white
    "
    >
      <div
        className="
        container mx-auto flex
        w-full max-w-screen-lg
    flex-col
    items-center
    py-4
    px-2
    text-lg
    text-gray-500
    lg:flex-row
    "
      >
        <div className="mb-2 basis-full lg:mb-0 lg:basis-1/3">
          <ChemifyLogo size="small" />
        </div>
        <div className="basis=full lg:basis-2/3">
          <p className="text-center lg:text-left">
            Copyright {new Date().getFullYear()} - Chemify{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};
