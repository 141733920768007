import { useEffect } from "react";

import App from "App";
import { LegalDisclaimer } from "components/LegalDisclaimer";
import { Layout } from "layout/Layout";
import { GApageView } from "utils/googleAnalyticsTracking";
import { PrivacyPolicy } from "../../components/legal/PrivacyPolicy";

export const PrivacyPolicyPage = () => {
  useEffect(() => {
    GApageView("privacy policy page");
  }, []);

  return (
    <App>
      <Layout title="ChemStarter - Privacy Policy">
        <PrivacyPolicy />
        <LegalDisclaimer />
      </Layout>
    </App>
  );
};
