import { useEffect } from "react";

import App from "App";
import { Layout } from "layout/Layout";
import { SuccessBanner } from "components/success/SuccessBanner";
import { LegalDisclaimer } from "components/LegalDisclaimer";
import { TopBidsTable } from "components/TopBidsTable";
import { GApageView } from "utils/googleAnalyticsTracking";

export const SuccessPage = () => {
  useEffect(() => {
    GApageView("success page");
  }, []);

  return (
    <App>
      <Layout title="ChemStarter - Success!">
        <div className="container mx-auto max-w-screen-lg min-h-screen">
          <SuccessBanner />
          <TopBidsTable />
        </div>
        <LegalDisclaimer />
      </Layout>
    </App>
  );
};
