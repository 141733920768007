import React from "react";

export const LegalDisclaimer = () => {
  return (
    <div className="container mx-auto max-w-screen-lg text-gray-500 px-4 py-8">
      <h3>Legal Disclaimer</h3>
      <p>
        Successful submissions do not mean an order is made. Requests for
        illegal, toxic, or otherwise unsafe chemicals, or suspected
        intermediates for synthesis of the aforementioned compound classes, will
        not be retained.{" "}
      </p>
    </div>
  );
};
