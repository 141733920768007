import { useForm } from "react-hook-form";
import { createSearchParams, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import { FormStepThree } from "./FormStepThree";
import { FormStepTwo } from "./FormStepTwo";
import { FormStepOne } from "./FormStepOne";
import { trpc } from "utils/trpc";
import { SUCCESS_PAGE } from "constants/routeConstants";
import { ErrorMessage } from "components/ErrorMessage";
import { ChemicalQuantity } from "types/types";
import { GAevent } from "utils/googleAnalyticsTracking";

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  chemicalName: string | null;
  chemicalSmiles: string;
  quantity: ChemicalQuantity;
  purity: string;
  bidPrice: number;
  affiliation: string;
  additionalInfo: string;
};

const schema = z.object({
  firstName: z
    .string()
    .min(1, { message: "Please complete this required field" })
    .max(255, { message: "First Name must be less than 255 characters" }),
  lastName: z
    .string()
    .min(1, { message: "Please complete this required field" })
    .max(255, { message: "Last Name must be less than 255 characters" }),
  email: z
    .string()
    .email()
    .min(1, { message: "Please complete this required field" })
    .max(255),
  chemicalName: z
    .string()
    .min(1, {
      message: "You must confirm the chemical drawn before submitting",
    })
    .max(255),
  chemicalSmiles: z
    .string()
    .min(1, { message: "Please complete this required field" })
    .max(255, { message: "SMILES must be less than 255 characters" }),
  quantity: z
    .string()
    .min(1, { message: "Please complete this required field" })
    .max(255, { message: "Quantity must be less than 255 characters" }),
  purity: z
    .string()
    .min(1, { message: "Please complete this required field" })
    .max(255, { message: "Purity must be less than 255 characters" }),
  bidPrice: z
    .number()
    .positive({ message: "Bid Price must be greater than 0" })
    .min(1, { message: "Please complete this required field" }),
  affiliation: z
    .string()
    .min(1, { message: "Please complete this required field" })
    .max(500, { message: "Affiliation must be less than 500 characters" }),
  additionalInfo: z.string().optional(),
});

export const RequestChemicalForm = () => {
  const navigate = useNavigate();

  const utils = trpc.useContext();
  const { mutateAsync, isLoading, isError } = trpc.add.useMutation({
    async onSuccess() {
      // refetches posts after a post is added
      await utils.list.invalidate();
    },
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      affiliation: "",
      chemicalName: "",
      chemicalSmiles: "",
      quantity: ChemicalQuantity["250Milligrams"],
      purity: "95",
      additionalInfo: "",
      bidPrice: 0,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    GAevent("chemical search", "form submit", false);

    const formattedData = {
      ...data,
      chemicalName: data.chemicalName || "",
      bidPrice: Math.round(data.bidPrice),
    };
    try {
      await mutateAsync(formattedData);
      reset();
      navigate({
        pathname: SUCCESS_PAGE,
        search: createSearchParams({
          chemical: formattedData.chemicalSmiles,
          firstName: formattedData.firstName,
          quantity: formattedData.quantity,
          bidPrice: String(formattedData.bidPrice),
        }).toString(),
      });
    } catch (cause) {
      console.error({ cause }, "Failed to add post");
    }
  });

  return (
    <form className="w-full" onSubmit={onSubmit}>
      <FormStepOne setValue={setValue} />
      <FormStepTwo register={register} errors={errors} />
      <FormStepThree isSubmitting={isLoading} control={control} />
      {isError && (
        <ErrorMessage>
          Something went wrong. Please check your input and try again.
        </ErrorMessage>
      )}
    </form>
  );
};
