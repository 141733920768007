type LinkButtonProps = {
  children: string | React.ReactNode;
  url: string;
  color: "primary" | "secondary";
  openInNewTab?: boolean;
};

export const LinkButton = ({
  children,
  url,
  color,
  openInNewTab,
}: LinkButtonProps) => {
  return (
    <a
      href={url}
      className={`flex w-[100%] max-w-[240px] items-center text-xl justify-center py-4 px-2 text-white transition delay-75 rounded-md ${
        color === "primary"
          ? "bg-purple-500 hover:bg-purple-700"
          : "bg-blue-300 hover:bg-blue-400"
      }`}
      target={openInNewTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
